<template lang="pug">
	div.default-modal
		loading(:active.sync="busy", :is-full-page="false")
		.modal-header
			.modal-title Edit Placement <span v-if="placement">({{ placement.id }})</span>
			a.close(href="javascript:void(0);", @click="$emit('close')")
				i.la.la-times
		.modal-body
			.modal-body-inner
				form(@submit.prevent="submit", v-if="placement")

					.row
						.col-sm-6
							.form-group
								label Status
								select.form-control(v-model="placement.status", name="status")
									option(v-for="o in statusOptions", :value="o.v") {{ o.t }}
					.row
						.col-sm-6
							.form-group
								label Conversion Cap
								input.form-control(type="text", placeholder="0", v-model="placement.daily_conversion_cap")
						.col-sm-6
							.form-group
								label Click Cap
								input.form-control(type="text", placeholder="0", v-model="placement.daily_click_cap")
					div(v-if="USER.permissions['placements::RISK']")
						h4.widget-section-title Risk
						.row
							.col-sm-6
								.form-group
									label &nbsp;
									div.toggle-wrapper
										toggle-button(v-model="placement.custom_report", :width="40", :height="20", :font-size="14")
										span.lbl Override auto-report
						.group-block(:class="{'group-block-disabled':!placement.custom_report}")
							.row
								.col-sm-6
									.form-group
										label &nbsp;
										div.toggle-wrapper
											toggle-button(v-model="placement.auto_report", :width="40", :height="20", :font-size="14")
											span.lbl Auto-report conversions
								.col-sm-6
									.form-group
										label Risk Management
										.input-group
											input.form-control(type="text", placeholder="", v-model.number="placement.risk_management")
											div.input-group-append
												span.input-group-text %
						p.clearfix &nbsp;
						.row
							.col-sm-6
								.form-group
									label Calculate risk from
									.input-group
										input.form-control(type="text", v-model="placement.risk_calc_from", placeholder="Calculate from")
										div.input-group-append
											a.input-group-text(href="javascript:void(0)", @click="setCalcFromNow()")
												i.la.la-clock
							.col-sm-6
								.form-group
									label Risk mode
									select.form-control(v-model="placement.risk_calc_mode")
										option(value="normal") Normal
										option(value="daily") Daily

					p.clearfix &nbsp;
					.buttons.text-right
						button.btn.btn-primary(type="submit") Save
							i.la.la-fw.la-save
</template>
<script>
	import Vue from 'vue';
	import moment from 'moment';

	export default {
		name: 'QuickEditPlacementModal',
		props: [
			'id',
		],
		methods: {
			setCalcFromNow() {
				this.placement.risk_calc_from = moment().format('DD/MM/YYYY HH:mm');
			},
			async submit(){
				if (this.busy) {
					return;
				}
				let data = Vue.util.extend({}, this.placement);
				Vue.ovData.placement._booleans.forEach(f => {
					data[f] = data[f] ? 1 : 0;
				});
				this.busy = true;
				try {
					await this.$ovReq.post('placement/quickSave', data);
					this.$ovNotify.success('Placement has been saved');
					this.$emit('close');
				} catch (e) {
					console.error(e);
				}
				this.busy = false;
			}
		},
		computed: {
			USER(){
				return this.$store.state.user;
			}
		},
		data(){
			return {
				busy: false,
				placement: null,
				statusOptions: [
					{v: 'draft', t: 'Draft'},
					{v: 'live', t: 'Live'},
					{v: 'paused', t: 'Paused'},
					{v: 'cancelled', t: 'Cancelled'},
					{v: 'ended', t: 'Ended'}
				]
			};
		},
		async created(){
			this.busy = true;
			try {
				this.placement = await this.$ovData.placement.get(this.id, true);
			} catch (e){
				console.error(e);
				this.$emit('close');
			}
			this.busy = false;
		}

	}
</script>

<template lang="pug">
	div(:class="{'edit-inline': !wrapped, 'edit-mode':editModeActive}")
		span(v-if="!notext && !r['_edit_'+field]", :class="'format-'+type") {{ format === 'number' ? r[field].toLocaleString() : r[field] }}
		textarea.form-control.form-control-sm(v-model="r['_'+field]", v-if="r['_edit_' + field] && type==='textarea'")
		input.form-control.form-control-sm(type="text", v-model="r['_'+field]", v-if="r['_edit_' + field] && type==='input'")
		span.quickedit-buttons
			a(href="javascript:void(0)", @click="editRecord(r, field)", v-if="!r['_edit_' + field]")
				i.la.la-pencil
			a(href="javascript:void(0)", @click="cancelEditRecord(r, field)", v-if="r['_edit_' + field]")
				i.la.la-times
			a(href="javascript:void(0)", @click="saveRecord(r, field)", v-if="r['_edit_' + field]")
				i.la.la-save
</template>
<script>
	import Vue from 'vue';

	export default {
		name: 'quick-edit',
		props: {
			notext: {
				type: Boolean,
				default(){
					return false;
				}
			},
			wrapped: Boolean,
			r: {
				type: Object
			},
			format: {
				type: String,
				default(){
					return 'plain';
				}
			},
			type: {
				type: String,
				default(){
					return 'input';
				}
			},
			field: {
				type: String
			}
		},
		computed: {
			editModeActive: function (){
				return this.editMode;
			}
		},
		data(){
			return {
				editMode: false
			};
		},
		methods: {
			editRecord(r, field){
				this.editMode = true;
				Vue.set(r, '_' + field, r[field]);
				Vue.set(r, '_edit_' + field, true);
				r = r[field];
			},
			cancelEditRecord(r, field){
				this.editMode = false;
				Vue.set(r, '_edit_' + field, false);
			},
			async saveRecord(r, field){
				// if (this.format === 'number'){
				// 	r[field] = +r[field];
				// }
				this.$emit('on-save', r, field);
			}
		}
	}
</script>

<template lang="pug">
	th
		.th-body(@click="toggleSort()")
			div.th-body-inner
				slot
			i.la(:class="iconClass")
</template>
<script>
	export default {
		name: 'SortColumn',
		props: {
			field: String,
			sorter: Object
		},
		computed: {
			iconClass(){
				let s = 'la-sort-' + ((this.sorter.field == this.field && this.sorter.direction === 'asc') ? 'up' :
					(this.sorter.field == this.field && this.sorter.direction === 'desc' ? 'down' : ''));
				return s;
			},
			// colSorter(){
			// 	return this.sorter;
			// }
		},
		data(){
			return {};
		},
		methods: {
			toggleSort(){
				let sorter = {
					field: this.sorter.field,
					direction: this.sorter.direction,
				};
				if (sorter.field === this.field) {
					sorter.direction = sorter.direction === 'asc' ? 'desc' : 'asc';
				}
				sorter.field = this.field;
				this.$emit('sort', sorter);
			}
		}
	}
</script>

// import store from "@/core/services/store/index";
// import {OVERRIDE_PAGE_LAYOUT_CONFIG} from "@/core/services/store/config.module";
// import HtmlClass from "@/core/services/htmlclass.service";

export const SET_PAGE_BUTTONS = 'setPageButtons';
export const SET_BACK_BUTTON = 'setBackButton';
export const CLEAR_PAGE_BUTTONS = 'clearPageButtons';

export default {
	state: {
		backButton: null,
		buttons: []
	},
	getters: {
		getButtons: state => () => {
			return state.buttons;
		},
		getBackButton: state => () => {
			return state.backButton;
		}
	},
	actions: {
		[CLEAR_PAGE_BUTTONS](context){
			context.commit('setPageButtons', []);
			context.commit('setBackButton', null);
		},
		[SET_PAGE_BUTTONS](context, payload){
			context.commit('setPageButtons', payload);
		},
		[SET_BACK_BUTTON](context, payload){
			context.commit('setBackButton', payload);
		}
	},
	mutations: {
		setPageButtons(state, payload){
			state.buttons = payload;

			// store.dispatch(OVERRIDE_PAGE_LAYOUT_CONFIG, {subheader: {display: true}});
			// // store.dispatch(SET_LAYOUT_CONFIG, {subheader: {display: true}});
			// HtmlClass.init({subheader: {display: true}});

		},
		setBackButton(state, payload){
			state.backButton = payload;
		}
	}
};

<template lang="pug">
	.form-group.form-group-sm.fg-advertiser
		label Advertiser ({{ visibleAdvertiserOptions.length }})
			b-checkbox(switch="", v-model="advertiserOptionsArchive")
		multiselect(:multiple="this.multiple", :options="visibleAdvertiserOptions",
			:value="value", @input="onChange", track-by="v", label="t", deselect-label="", select-label="")
</template>
<script>
import Vue from 'vue';

export default {
	name: 'SelectAdvertiser',
	props: {
		value: [Array, Object],
		multiple: {
			type: Boolean,
			default: true,
			required: false
		}
	},
	computed: {
		visibleAdvertiserOptions(){
			return this.advertiserOptions.filter(a => {
				return this.advertiserOptionsArchive || a.active;
			});
		},
	},
	data(){
		return {
			advertiserOptions: [],
			advertiserOptionsArchive: false,
		};
	},
	methods: {
		onChange(value) {
			this.$emit('input', value);
		}
	},
	async mounted(){
		this.advertiserOptions = await Vue.$dwData.advertiser.getOptions(true);

	}
}
</script>

<template lang="pug">
	.url-input-widget
		div.result-part
			.form-group
				label {{ label }}
				textarea.form-control(:value="selectedValue", @input="onChange", rows="4")
			div.text-right
				a(href="javascript:void(0);", @click="toggleEditor()") {{ showEditor ? 'Hide' : 'Show' }} editor
		//div.show-editor-block

		div.url-parts(v-if="showEditor")
			label Base
			input.form-control(:value="parsedBaseUrl", v-on:blur="updateBase")
			table.url-params
				thead
					tr
						th Parameter
						th Value
						th.text-center(style="50px")
							button.btn.btn-primary.btn-circle.btn-sm(type="button", @click="addParam()")
								i.la.la-plus
				tbody
					tr(v-for="(p, i) in parsedParams")
						td
							input(type="text", :value="p.k", @input="updateParam($event, p, i)")
						td
							input(type="text", :value="p.v", @input="updateValue($event, p, i)")
						td.text-center
							button.btn.btn-default.btn-circle.btn-sm(type="button", @click="removeParam(i)")
								i.la.la-times
		div.macros-part(v-if="showEditor && macros && macros.length")
			table.macros-list.table
				thead
					tr
						th Macro
						th Description
						th Aliases
				tbody
					tr(v-for="m in macros")
						td {{ '{' + m.param + '}' }}
						td {{ m.description }}
						td
							span(v-if="m.aliases  && m.aliases.length")
								span {{ m.aliases.join(', ') }}
								//span(v-for="a in m.aliases") {{  a }},


</template>
<style lang="scss">
.url-input-widget {
	border: 1px solid #ddd;
	border-radius: 5px;
	.result-part {
		padding: 15px;
		.form-group {
			margin-bottom: 0;
		}
	}
	.url-parts {
		border-top: 1px dashed #ccc;
		background: #fafafa;
		padding: 15px;
	}
	.macros-part {
		border-top: 1px dashed #ccc;
		//background: #fafafa;
		padding: 15px;
		.macros-list.table {
			width: 100%;
			thead {
				tr {
					th {
						padding: 3px;
					}
				}
			}
			tbody {
				tr {
					td {
						padding: 2px 3px;
					}
				}
			}
		}
	}
}
table.url-params {
	th {
		font-weight: normal;
		color: #ccc;
	}
	td {
		padding: 0;
	}
	input[type="text"] {
		border: 1px solid #eee;
		height: 24px;
		line-height: 22px;
	}
	.btn-default {
		width: 24px;
		height: 24px;
		line-height: 24px;
		padding: 0;
		font-size: 12px;
	}
	.btn-primary {
		width: 24px;
		height: 24px;
		line-height: 24px;
		padding: 0;
		font-size: 16px;
	}
}
</style>
<script>
export default {
	name: 'URLInput',
	props: {
		label: String,
		value: String,
		macros: {
			type: Array,
			required: false
		}
	},
	data() {
		return {
			showEditor: false
		};
	},
	computed: {
		parsedParams(){
			try {
				let uStr = this.value;
				let parsedUrl = uStr.split('?', 2);
				let params = [];
				if (parsedUrl.length === 2){
					let uParams = parsedUrl[1].split('&');
					for (let i=0; i<uParams.length; i++){
						let parsedParam = uParams[i].split('=', 2);
						params.push({k:parsedParam[0], v: parsedParam[1]});
					}
				}
				return params;
			} catch (e){
				return [];
			}
		},
		parsedBaseUrl(){
			try {
				return this.value;
				// let u = new URL(this.value);
				// return u.origin + u.pathname;
			} catch (e){
				return '';
			}
		},
		selectedValue(){
			return this.value;
		}
	},
	methods: {

		parseUrl(url){
			try {
				let uStr = url;
				let parsedUrl = uStr.split('?', 2);
				let params = [];
				if (parsedUrl.length === 2){
					let uParams = parsedUrl[1].split('&');
					for (let i=0; i < uParams.length; i++){
						let parsedParam = uParams[i].split('=', 2);
						params.push({k:parsedParam[0], v: parsedParam[1]});
					}
				}
				return {
					base: parsedUrl[0],
					params
				};
			} catch (e){
				return {
					base: url,
					params: []
				};
			}
		},

		toggleEditor(){
			this.showEditor = !this.showEditor;
		},
		removeParam(idx){
			let parsedUrl = this.parseUrl(this.value);
			let baseUrl = parsedUrl.base;
			baseUrl += '?';
			for (let i=0; i < parsedUrl.params.length; i++){
				if (i !== idx) {
					if (i > 0){
						baseUrl += '&';
					}
					baseUrl += parsedUrl.params[i].k + '=' + parsedUrl.params[i].v;
				}
			}
			this.$emit('input', baseUrl);
		},
		addParam(){
			let newUrl = this.value;
			newUrl += (newUrl.indexOf('?') > -1 ? '&' : '?') + 'key=val';
			newUrl = newUrl.replace('&&', '&');
			this.$emit('input', newUrl);
		},
		onChange(evt) {
			// console.log(evt.target.value);
			this.$emit('input', evt.target.value);
		},
		updateBase(evt){
			try {
				let newBase = evt.target.value;
				let params = '';
				if (this.value.indexOf('?') > -1){
					let parsed = this.value.split('?', 2);
					params = parsed[1];
				}
				if (params){
					newBase += '?' + params;
				}
				this.$emit('input', newBase);
			} catch (e) {
				// do nothing
			}
		},
		updateParts(evt){
			console.log(evt);
		},
		updateParam(evt, p, idx){
			let parsedUrl = this.parseUrl(this.value);
			let baseUrl = parsedUrl.base;
			baseUrl += '?';
			for (let i=0; i < parsedUrl.params.length; i++){
				if (i > 0){
					baseUrl += '&';
				}
				baseUrl += (i === idx ? evt.target.value : parsedUrl.params[i].k) + '=' + parsedUrl.params[i].v;
			}
			this.$emit('input', baseUrl);
		},
		updateValue(evt, p, idx){
			let parsedUrl = this.parseUrl(this.value);
			let baseUrl = parsedUrl.base;
			baseUrl += '?';
			for (let i=0; i < parsedUrl.params.length; i++){
				if (i > 0){
					baseUrl += '&';
				}
				baseUrl += parsedUrl.params[i].k + '=' + (i === idx ? evt.target.value : parsedUrl.params[i].v);
			}
			this.$emit('input', baseUrl);
		}
	},
}
</script>

<template lang="pug">
	.form-group.form-group-sm.fg-placement
		label Placements
		multiselect(:multiple="this.multiple", :options="placementOptions",
			:value="value", @input="onChange", track-by="v", label="t", deselect-label="", select-label="")
</template>
<script>
import Vue from 'vue';

export default {
	name: 'SelectPlacement',
	props: {
		value: [Array, Object],
		multiple: {
			type: Boolean,
			default: true,
			required: false
		}
	},
	data(){
		return {
			placementOptions: []
		};
	},
	methods: {
		onChange(value) {
			this.$emit('input', value);
		}
	},
	async mounted(){
		this.placementOptions = await Vue.$dwData.placement.getOptions(true);

	}
}
</script>

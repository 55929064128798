import Vue from 'vue';


const DataService = {

	install(Vue/*, options*/){

		let api = {

			common: {
				getSelectedFromString: function (options, str){
					let selected = [];
					if (str) {
						str += '';
						let itemList = str.split(',').map(r => +r).filter(r => r);
						options.forEach(o => {
							if (itemList.indexOf(o.v) > -1) {
								selected.push(o);
							}
						});
					}
					return selected;
				}
			},

			general: {
				exchangeOptions: [
					{v: 'bucksense', t: 'BuckSense'},
					{v: 'kayzen', t: 'Kayzen'},
					{v: 'mediasmart', t: 'MediaSmart'},
				],
				mmpOptions: [
					{v: 'appsflyer', t: 'AppsFlyer'},
					{v: 'kochava', t: 'Kochava'},
					{v: 'adjust', t: 'Adjust'},
					{v: 'singular', t: 'Singular'},
					{v: 'branch', t: 'Branch'},
					{v: 'tenjin', t: 'Tenjin'},
					{v: 'tune', t: 'Tune'},
				],
				platformOptions: [
					{v: 'android', t: 'android'},
					{v: 'ios', t: 'ios'},
					{v: 'web', t: 'web'},
				],
				placementStatusOptions: [
					{v: 'draft', t: 'Draft'},
					{v: 'live', t: 'Live'},
					{v: 'paused', t: 'Paused'},
					{v: 'canceled', t: 'Canceled'},
				],
				offerStatusOptions: [
					{v: 'draft', t: 'Draft'},
					{v: 'live', t: 'Live'},
					{v: 'paused', t: 'Paused'},
					{v: 'canceled', t: 'Canceled'},
				],
				offerTypeOptions: [
					{v: 'offer', t: 'Normal'},
					{v: 'asset', t: 'Asset'},
					{v: 'custom', t: 'Custom'},
					{v: 'targeting', t: 'Targeting'},
				],
				currencyOptions: [
					{v: 'USD', t: 'USD'},
					{v: '%', t: '%'},
				],

				timezoneOptions: [
					{v: -9, t: '-09:00'},
					{v: -8, t: '-08:00'},
					{v: -7, t: '-07:00'},
					{v: -6, t: '-06:00'},
					{v: -5, t: '-05:00'},
					{v: -4, t: '-04:00'},
					{v: -3, t: '-03:00'},
					{v: -2, t: '-02:00'},
					{v: -1, t: '-01:00'},
					{v: 0, t: '+00:00 UTC'},
					{v: 1, t: '+01:00'},
					{v: 2, t: '+02:00'},
					{v: 3, t: '+03:00'},
					{v: 4, t: '+04:00'},
					{v: 5, t: '+05:00'},
					{v: 6, t: '+06:00'},
					{v: 7, t: '+07:00'},
					{v: 8, t: '+08:00'},
					{v: 9, t: '+09:00'},
				],
				payoutModelOptions: [{v: 'CPA'}, {v: 'CPI'}, {v: 'CPR'}, {v: 'CPC'}, {v: 'CPM'}, {v: 'CPS'}],


				getIntegrations: async function (){
					if (!this.integrationTypeOptions) {
						try {
							let resp = await Vue.$api.get('advertisers/getIntegrations');
							this.integrationTypeOptions = resp.integrations;
						} catch (e) {
							console.error(e);
						}
					}
					return this.integrationTypeOptions;
				},
			},

			country: {
				_options:[],
				_hash: {},
				async getOptions(reload){
					if (reload || this._options.length === 0){
						let resp = await Vue.$api.get('geo/getCountries', {params:{page_size:10000}});
						this._options = resp.records;
					}
					return this._options;
				}
			},
			region: {
				_options: [],
				_hash: {},
				async getOptions(reload, country = null) {
					if(reload || this._options.length === 0) {
						let resp = country ? await Vue.$api.get('geo/getRegions', {params:{page_size:10000, country }}) : await Vue.$api.get('geo/getRegions', {params:{page_size:10000}});
						this._options = resp.records;
					}
					return this._options;
				},
				async getOptionsByCountry(countries) {
					try {
						let resp = await Vue.$api.get('geo/getRegions', {params: countries});
						return resp.records;

					} catch (e) {
						throw e
					}
				}
			},

			city: {
				_options: [],
				_hash: {},
				async getOptions(reload, country = null) {
					if(reload || this._options.length === 0) {
						let resp = country ? await Vue.$api.get('geo/getCities', {params:{page_size:10000, country }}) : await Vue.$api.get('geo/getCities', {params:{page_size:10000}});
						this._options = resp.records;
					}
					return this._options;
				},
				async getOptionsByCountry(countries) {
					try {
						let resp = await Vue.$api.get('geo/getCities', {params: countries});
						return resp.records;
					} catch (e) {
						throw e
					}
				}
			},


			advertiser: {
				_booleans: [
					// 'status', 'fetch_api', 'auto_placements',
					// 'fetch_api_override_tracking',
					// 'fetch_api_override_events',
					// 'require_secret',
					// 'default_event_convert_payout', 'default_event_managed', 'default_require_validation',
					// 'api_load_active_only',
					// 'default_enable_smartlink', 'default_hide_offers',
				],
				_options: [],
				_hash: {},
				newInstance(){
					return {
						id: 0,
						network_id: 0,
						status: false,
						name: '',
						api_sync_caps: true,
						api_auto_run: false,
						api_auto_create: false,
						api_auto_tag: false,
						fetch_api: false,
						convert_on_payout: false,
						auto_publishers: []
						// code: '',
						// auto_placements: false,
						// mask_source: true,
						// fetch_api: false,
						// integration_type: null,
						// scan_frequency: -1,
						// redirect_type: 302,
						// score_offset: 302,
						// daily_subsource_limit: 0,
						// total_subsource_limit: 0,
						// integration_params: {},
						// tracking_link_params: [],
						// blocked_publishers: []
					};
				},
				async get(id, reload){
					if (!this._hash[id] || reload) {
						let resp = await Vue.$api.get('advertisers/get/' + id);
						this._hash[id] = resp.entity;
					}
					return this._hash[id];
				},
				async getOptions(reload){
					if (!this._options.length || reload) {
						let resp = await Vue.$api.get('advertisers/getOptions');
						this._options = resp.options;
					}
					return this._options;
				}
			},
			publisher: {
				_booleans: [],
				_options: [],
				_hash: {},
				newInstance(){
					return {
						id: 0,
						network_id: 0,
						status: false,
						support_advanced_privacy: true,
						name: '',
						comments: '',
						risk: 70,
						payout_percent: 70,
						postbacks: [],
						postback_mode: 'conversions'
					};
				},
				async get(id, reload){
					if (!this._hash[id] || reload) {
						let resp = await Vue.$api.get('publishers/get/' + id);
						this._hash[id] = resp.entity;
					}
					return this._hash[id];
				},
				async getOptions(reload){
					if (!this._options.length || reload) {
						let resp = await Vue.$api.get('publishers/getOptions');
						this._options = resp.options;
					}
					return this._options;
				}
			},
			asset: {
				_booleans: [],
				_options: [],
				_hash: {},
				newInstance(){
					return {
						id: 0,
						name: '',
						package_id: '',
					};
				},
				async get(id, reload){
					if (!this._hash[id] || reload) {
						let resp = await Vue.$api.get('assets/get/' + id);
						this._hash[id] = resp.entity;
					}
					return this._hash[id];
				},
				async getOptions(reload){
					if (!this._options.length || reload) {
						let resp = await Vue.$api.get('assets/getOptions');
						this._options = resp.options;
					}
					return this._options;
				}
			},
			offer: {
				_booleans: [],
				_options: [],
				_hash: {},
				newInstance() {
					return {
						id: 0,
						network_id: 0,
						status: 'draft',
						type: 'offer',
						name: '',
						comments: '',
						payout_model: 'CPI',
						payout: 0,
						advertiser_id: 0,
						currency: 'USD',
						mmp: null,
						impression_pacing_min: 0,
						impression_pacing_hour: 0,
						click_pacing_min: 0,
						click_pacing_hour: 0,
						daily_click_cap: 0,
						daily_impression_cap: 0,
						daily_click_goal: 100000,
						daily_impression_goal: 0,
						daily_conversion_cap: 0,
						daily_subsource_click_cap: 0,
						daily_subsource_limit: 0,
						total_subsource_limit: 0,
						click_url: '',
						impression_url: '',
						description: '',
						kpi_description: '',
						events: [],
						sign_clicks: false,
						direct_link: false,
						scan_required: false,
						require_device_id: false,
						require_appname: false,
						require_bundle: false,
						incent: false,
						subOffers: [],
						keyword_mode: null,
						sub1_mode: null,
						asset_mode: null,
						geo_params: [],
					};
				},
				async get(id, reload){
					if (!this._hash[id] || reload) {
						let resp = await Vue.$api.get('offers/get/' + id);
						this._hash[id] = resp.entity;
					}
					return this._hash[id];
				},
				async getOptions(reload){
					if (!this._options.length || reload) {
						let resp = await Vue.$api.get('offers/getOptions');
						this._options = resp.options;
					}
					return this._options;
				},
				async getSelectedOptions(str){
					let selected = [];
					if (str) {
						str += '';
						let itemList = str.split(',').map(r => +r).filter(r => r);
						if (itemList.length) {
							let resp = await Vue.$api.get('offers/getOptionsById', {params: {offer_id: itemList.join(',')}});
							selected = resp.options;
						}
					}
					return selected;
				},
				async prepareFromExternal(extOfferId){
					let resp = await Vue.$api.get('offers/prepareFromExternal/'+extOfferId);
					return resp.offer;
				}
			},
			dataControl: {
				_options: [],
				_hash: {},
				newInstance(){
					return {
						id: 0,
						network_id: 0,
						name: '',
						platform: '',
						countries: '',
						keyword_override: '',
						sub1_override: '',
						asset_override: [],
						num_sources: 100,
						sub1_rotate: true,
						sub1_generate_mode: 'random'
					};
				},
				async get(id, reload){
					if (!this._hash[id] || reload) {
						let resp = await Vue.$api.get('data-control/get/' + id);
						this._hash[id] = resp.entity;
					}
					return this._hash[id];
				},
				async getOptions(reload){
					if (!this._options.length || reload) {
						let resp = await Vue.$api.get('data-control/getOptions');
						this._options = resp.options;
					}
					return this._options;
				},
			},
			placement: {
				_options: [],
				_hash: {},
				newInstance(offer){
					return {
						id: 0,
						network_id: 0,
						currency: offer ? offer.currency : 'USD',
						override_name: '',
						payout: offer ? offer.payout : 0,
						payout_model: offer ? offer.payout_model : 'CPI',
						offer_id: offer ? offer.id : null,
						advertiser_id: offer ? offer.advertiser_id : null,
						custom_click_url: '',
						custom_impression_url: '',
						fallback_click_url: '',
						fallback_impression_url: '',
						daily_conversion_cap: 0,
						daily_click_cap: 0,
						daily_impression_cap: 0,
						daily_subsource_click_cap: 0,
						daily_subsource_limit: 0,
						total_subsource_limit: 0,
						click2impression_mode: 'none',
						impression_ratio: 0,
						redirect_type: 0,
						skip_geo_check: false,
					};
				},
				async get(id, reload){
					if (!this._hash[id] || reload) {
						let resp = await Vue.$api.get('placements/get/' + id);
						this._hash[id] = resp.entity;
					}
					return this._hash[id];
				},
				async getOptions(reload){
					if (!this._options.length || reload) {
						let resp = await Vue.$api.get('placements/getOptions');
						this._options = resp.options;
					}
					return this._options;
				},
				async getSelectedOptions(str){
					let selected = [];
					if (str) {
						str += '';
						let itemList = str.split(',').map(r => +r).filter(r => r);
						if (itemList.length) {
							let resp = await Vue.$api.get('placements/getOptionsById', {params: {placement_id: itemList.join(',')}});
							selected = resp.options;
						}
					}
					return selected;
				}
			},

			creative: {
				_options: [],
				_hash: {},
				newInstance(){
					return {
						id: 0,
						name: null,
						url: null
					};
				},
				async get(id, reload){
					if (!this._hash[id] || reload) {
						let resp = await Vue.$api.get('creatives/get/' + id);
						this._hash[id] = resp.entity;
					}
					return this._hash[id];
				},
				async getOptions(reload){
					if (!this._options.length || reload) {
						let resp = await Vue.$api.get('creatives/getOptions');
						this._options = resp.options;
					}
					return this._options;
				},
			},
			tag: {
				_options: [],
				_hash: {},
				newInstance(){
					return {
						id: 0,
						width: 0,
						height: 0,
						name: null,
						banners: [],
						background_placements: [],
					};
				},
				async get(id, reload){
					if (!this._hash[id] || reload) {
						let resp = await Vue.$api.get('tags/get/' + id);
						this._hash[id] = resp.entity;
					}
					return this._hash[id];
				},
				async getOptions(reload){
					if (!this._options.length || reload) {
						let resp = await Vue.$api.get('tags/getOptions');
						this._options = resp.options;
					}
					return this._options;
				},
			},
			user: {
				_booleans: [],
				_options: [],
				_hash: {},
				newInstance(){
					return {
						id: 0,
						level: 1,
						first_name: '',
						last_name: '',
					};
				},
				async get(id, reload){
					if (!this._hash[id] || reload) {
						let resp = await Vue.$api.get('users/get/' + id);
						this._hash[id] = resp.entity;
					}
					return this._hash[id];
				},
				async getOptions(reload){
					if (!this._options.length || reload) {
						let resp = await Vue.$api.get('users/getOptions');
						this._options = resp.options;
					}
					return this._options;
				}
			},

		};


		Vue.$dwData = api;
		Vue.prototype.$dwData = api;
	}

};


Vue.use(DataService);

export default DataService;

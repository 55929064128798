import Vue from 'vue';
import Router from 'vue-router';


Vue.use(Router);

const withPrefix = (prefix, routes) =>
	routes.map((route) => {
		route.path = prefix + route.path;
		return route;
	});

export default new Router({
	mode: 'history',
	base: process.env.BASE_URL,
	routes: [
		{
			path: '/',
			redirect: '/app'
		},
		{
			path: '/app',
			component: () => import('@/views/layout/Layout'),
			children: [
				{
					path: '',
					name: 'dashboard',
					component: () => import('@/views/pages/Dashboard.vue')
				},
				{
					path: 'reports',
					name: 'reports',
					component: () => import('@/views/pages/media/reports/Reports.vue')
				},
				{
					path: 'reports/postback-log',
					name: 'postback-log',
					component: () => import('@/views/pages/media/reports/PostbackLog.vue')
				},
				{
					path: 'reports/publisher-postbacks',
					name: 'publisher-postbacks',
					component: () => import('@/views/pages/media/reports/PublisherPostbacks.vue')
				},
				{
					path: 'reports/clicks',
					name: 'clicks',
					component: () => import('@/views/pages/media/reports/ClickList.vue')
				},
				{
					path: 'reports/clicks/:id',
					name: 'click-view',
					component: () => import('@/views/pages/media/reports/ClickView.vue')
				},
				{
					path: 'reports/events',
					name: 'events',
					component: () => import('@/views/pages/media/reports/Events.vue')
				},
				{
					path: 'reports/rejection-events',
					name: 'rejection-events',
					component: () => import('@/views/pages/media/reports/RejectionEvents.vue')
				},
				...withPrefix('demand/advertisers', [
					{
						name: 'advertiser-list', path: '',
						component: () => import('@/views/pages/media/advertisers/List.vue')
					},
					{
						name: 'advertiser-add', path: '/add',
						component: () => import('@/views/pages/media/advertisers/Form.vue')
					},
					{
						name: 'advertiser-edit', path: '/edit/:id',
						component: () => import('@/views/pages/media/advertisers/Form.vue')
					}
				]),

				{
					name: 'external-offer-list', path: 'demand/external-offers',
					component: () => import('@/views/pages/media/offers/ExternalOfferList.vue')
				},
				...withPrefix('demand/offers', [
					{
						name: 'offer-list', path: '',
						component: () => import('@/views/pages/media/offers/List.vue')
					},
					{
						name: 'offer-add', path: '/add',
						component: () => import('@/views/pages/media/offers/Form.vue')
					},
					{
						name: 'offer-edit', path: '/edit/:id',
						component: () => import('@/views/pages/media/offers/Form.vue')
					}
				]),
				...withPrefix('demand/assets', [
					{
						name: 'asset-list', path: '',
						component: () => import('@/views/pages/media/assets/List.vue')
					},
					{
						name: 'asset-add', path: '/add',
						component: () => import('@/views/pages/media/assets/Form.vue')
					},
					{
						name: 'asset-edit', path: '/edit/:id',
						component: () => import('@/views/pages/media/assets/Form.vue')
					}
				]),
				...withPrefix('demand/data-control', [
					{
						name: 'data-control-list', path: '',
						component: () => import('@/views/pages/media/data-control/List.vue')
					},
					{
						name: 'data-control-add', path: '/add',
						component: () => import('@/views/pages/media/data-control/Form.vue')
					},
					{
						name: 'data-control-edit', path: '/edit/:id',
						component: () => import('@/views/pages/media/data-control/Form.vue')
					},
					{
						name: 'source-blacklist', path: '/source-blacklist',
						component: () => import('@/views/pages/media/data-control/SourceBlacklist.vue')
					}
				]),

				...withPrefix('supply/creatives', [
					{
						name: 'creative-list', path: '',
						component: () => import('@/views/pages/media/creatives/List.vue')
					},
					{
						name: 'creative-add', path: '/add',
						component: () => import('@/views/pages/media/creatives/Form.vue')
					},
					{
						name: 'creative-edit', path: '/edit/:id',
						component: () => import('@/views/pages/media/creatives/Form.vue')
					}
				]),
				...withPrefix('supply/publishers', [
					{
						name: 'publisher-list', path: '',
						component: () => import('@/views/pages/media/publishers/List.vue')
					},
					{
						name: 'publisher-add', path: '/add',
						component: () => import('@/views/pages/media/publishers/Form.vue')
					},
					{
						name: 'publisher-edit', path: '/edit/:id',
						component: () => import('@/views/pages/media/publishers/Form.vue')
					}
				]),
				...withPrefix('supply/placements', [
					{
						name: 'placement-list', path: '',
						component: () => import('@/views/pages/media/placements/List.vue')
					},
					{
						name: 'placement-add', path: '/add',
						component: () => import('@/views/pages/media/placements/Form.vue')
					},
					{
						name: 'placement-edit', path: '/edit/:id',
						component: () => import('@/views/pages/media/placements/Form.vue')
					}
				]),
				...withPrefix('supply/tags', [
					{
						name: 'tag-list', path: '',
						component: () => import('@/views/pages/media/tags/List.vue')
					},
					{
						name: 'tag-add', path: '/add',
						component: () => import('@/views/pages/media/tags/Form.vue')
					},
					{
						name: 'tag-edit', path: '/edit/:id',
						component: () => import('@/views/pages/media/tags/Form.vue')
					}
				]),
				...withPrefix('settings/users', [
					{
						name: 'user-list', path: '',
						component: () => import('@/views/pages/media/users/List.vue')
					},
					{
						name: 'user-add', path: '/add',
						component: () => import('@/views/pages/media/users/Form.vue')
					},
					{
						name: 'user-edit', path: '/edit/:id',
						component: () => import('@/views/pages/media/users/Form.vue')
					}
				]),
				// {
				// 	path: 'media',
				// 	name: 'media',
				// 	component: () => import('@/views/pages/media/Layout.vue'),
				// 	children: [
				//
				// 	]
				// }
			]
		},
		{
			name: 'login',
			path: '/login',
			component: () => import("@/views/pages/Login")
		},
		{
			// the 404 route, when none of the above matches
			path: "/404",
			name: "404",
			component: () => import("@/views/pages/error/Error-1.vue")
		}
	]
});

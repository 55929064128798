import Vue from "vue";
import Vuex from "vuex";

import auth from "./auth.module";
import htmlClass, {ADD_BODY_CLASSNAME, REMOVE_BODY_CLASSNAME} from "./htmlclass.module";
import config, {OVERRIDE_PAGE_LAYOUT_CONFIG, RESET_LAYOUT_CONFIG} from "./config.module";
import breadcrumbs from "./breadcrumbs.module";
import profile from "./profile.module";
import page, {CLEAR_PAGE_BUTTONS, SET_PAGE_BUTTONS} from "./page.module";

Vue.use(Vuex);

const layoutPlugin = store => {
	store.subscribe((mutation, state) => {

		if (mutation.type === SET_PAGE_BUTTONS){
			if (mutation.payload && mutation.payload.length){
				store.dispatch(OVERRIDE_PAGE_LAYOUT_CONFIG, {subheader: {display: true}});
				store.dispatch(ADD_BODY_CLASSNAME, 'subheader-enabled');
				// htmlClass.add('subheader-enabled');
			}
		}
		if (mutation.type === RESET_LAYOUT_CONFIG){
			store.dispatch(CLEAR_PAGE_BUTTONS);
			store.dispatch(REMOVE_BODY_CLASSNAME, 'subheader-enabled');
		}
	});
};

export default new Vuex.Store({
	plugins: [layoutPlugin],
	modules: {
		auth,
		htmlClass,
		config,
		breadcrumbs,
		profile,
		page
	}
});

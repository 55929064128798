import Vue from 'vue';

let NotifyPlugin = {

	install(Vue/*, options*/){

		let api = {
			success: function(m, title){
				NotifyPlugin._instance.$bvToast.toast(m, {
					title: title || '',
					autoHideDelay: 5000,
					appendToast: true,
					variant: 'success',
					toaster: 'b-toaster-bottom-right'
				});
			},
			error: function(m, title){
				NotifyPlugin._instance.$bvToast.toast(m || 'There has been an error', {
					title: title || 'Error',
					autoHideDelay: 5000,
					appendToast: true,
					variant: 'danger',
					toaster: 'b-toaster-bottom-right'
				});
			}
		};

		Vue.$notify = api;
		Vue.prototype.$notify = api;
	}

};

export default NotifyPlugin;

<template lang="pug">
	v-select(:options="groupOptions", :value="selectedValue", @input="onChange", :reduce="o => o.v", label="t")
	//v-select(:from="groupOptions", :value="selectedValue", @input="onChange", as="t::v", label="t", :multiple="false")
</template>
<script>
import Vue from 'vue';

export default {
	name: 'SelectRemotePublisherGroup',
	props: {
		value: [Array, Object],
		multiple: {
			type: Boolean,
			default: true,
			required: false
		}
	},
	computed: {
		selectedValue(){
			return this.groupOptions.find(o => o.v === this.value);
		}
	},
	data(){
		return {
			groupOptions: [],
		};
	},
	methods: {
		onChange(value) {
			this.$emit('input', value);
		}
	},
	async mounted(){
		this.groupOptions = await Vue.$dwData.remotePublisherGroup.getOptions();

	}
}
</script>

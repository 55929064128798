<template lang="pug">
	.form-group.form-group-sm.fg-publisher
		label Remote Publishers
		//v-select(:options="publisherOptions", :value="value", @input="onChange", label="t")
		v-select(:options="publisherOptions", :value="selectedValue", @input="onChange", :reduce="o => o.remote_publisher_id", label="t")

</template>
<script>
import Vue from 'vue';

export default {
	name: 'SelectRemotePublisher',
	props: {
		value: [Array, Object],
		multiple: {
			type: Boolean,
			default: true,
			required: false
		}
	},
	computed: {
		selectedValue(){
			return this.publisherOptions.find(o => o.remote_publisher_id === this.value);
		}
	},
	data(){
		return {
			publisherOptions: [],
		};
	},
	methods: {
		onChange(value) {
			this.$emit('input', value);
		}
	},
	async mounted(){
		this.publisherOptions = await Vue.$dwData.remotePublisher.getOptions(true);

	}
}
</script>

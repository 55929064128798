<template lang="pug">
	.form-group.form-group-sm.fg-offer
		label Offers
		multiselect(:multiple="this.multiple", :options="offerOptions",
			:value="value", @input="onChange", track-by="v", label="t", deselect-label="", select-label="")
</template>
<script>
import Vue from 'vue';

export default {
	name: 'SelectOffer',
	props: {
		value: [Array, Object],
		multiple: {
			type: Boolean,
			default: true,
			required: false
		}
	},
	data(){
		return {
			offerOptions: [],
		};
	},
	methods: {
		onChange(value) {
			this.$emit('input', value);
		}
	},
	async mounted(){
		this.offerOptions = await Vue.$dwData.offer.getOptions(true);

	}
}
</script>

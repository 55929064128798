// action types
export const UPDATE_PERSONAL_INFO = "updateUserPersonalInfo";

// mutation types
export const SET_PERSONAL_INFO = "setPersonalInfo";

const state = {
  user_personal_info: {
    photo: "media/users/300_21.jpg",
    name: "John Doe",
    email: "john@gmail.com",
  },
  user_account_info: {
    username: "john",
    email: "john@gmail.com",
  }
};

const getters = {
  currentUserPersonalInfo(state) {
    return state.user_personal_info;
  },

};

const actions = {
  [UPDATE_PERSONAL_INFO](context, payload) {
    context.commit(SET_PERSONAL_INFO, payload);
  },
};

const mutations = {
  [SET_PERSONAL_INFO](state, user_personal_info) {
    state.user_personal_info = user_personal_info;
  },
};

export default {
  state,
  actions,
  mutations,
  getters
};
